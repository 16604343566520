
import Section from "../../components/Section";
import AnimatedHeader from "../../components/animations/AnimatedHeader";
import {attachLetterAnimations} from "../../utilities/DelayedAnimations";
import {useEffect} from "react";
import TextSphere from "../../components/TextSphere";
import {
	getFadeInVariants, getSlideRightFadeInVariants,
	getSlideUpFadeInVariants
} from "../../utilities/AnimationVariants";

const headerLines = [
	[
		{ word: "Skills &", className: "letter title-gradient", space: false },
	], [
		{ word: "Experience", className: "letter title-gradient", space: false },
	]
];

function SkillsText(props) {
	return (
			<div className={"padded-left-area"}>
				{
					props.isH1 ?
							<AnimatedHeader headerType={"h1"} lines={headerLines} /> :
							<AnimatedHeader headerType={"h2"} lines={headerLines} />
				}
				<p className={"text-section box-background"}>
					<span className={"code"}>&gt;&gt;  </span>
					<span>
						Since I started my front-end development journey of basic HTML/CSS/JS in 2020,
						I've been honing my programming skills by keeping pace with the evolution of modern
						web technologies.
					</span>
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					<span>
						I create Full Stack applications from idea conception to deployment while adapting to client requirements.
					</span>
					<br /><br />
					<span className={"code"}>&gt;&gt;  </span>
					<span>
						My programming standards make use of current best practices with an emphasis on
						performance, optimization, responsiveness and intuitive design.
					</span>
				</p>
			</div>
	)
}

const Skills = (props) => {
	useEffect(() => {
		attachLetterAnimations().catch(exception => console.log(exception));
	});

	return (
			<Section sectionClass={"grid-split" + (props.isOwnPage ? " individual-page" : " vertical-margin")}
							 elements={[
									 <SkillsText isH1={props.isOwnPage} />,
								 <TextSphere />
							 ]}
							 sectionVariants={getFadeInVariants()}
							 itemVariants={props.isOwnPage ? getSlideUpFadeInVariants() : getSlideRightFadeInVariants()} />
	);
}

export default Skills;
