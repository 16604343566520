
import React, {useRef} from "react";
import {useLocation} from "react-router-dom";
import {Navigation} from "./Navigation";
import {MenuToggle} from "./MenuToggle";
import {motion, useCycle} from "framer-motion";
import {NavLinks} from "./NavLinks";
import {useDimensions} from "../hooks/useDimensions";

const sidebar = {
	open: {
		clipPath: "inset(0% 0% 0% 0% round 0px)",
		transition: {
			type: "spring",
			stiffness: 50,
			restDelta: 10
		}
	},
	closed: {
		clipPath: "inset(0.75dvh 1dvh 92.05dvh 0.8dvh round 10px)",
		transition: {
			delay: 0.3,
			type: "spring",
			stiffness: 400,
			damping: 40
		}
	}
};

const Navbar = () => {
	const currentPath = useLocation().pathname;
	const className = "full-media-only";
	const [isOpen, toggleOpen] = useCycle(false, true);
	const containerRef = useRef(null);
	const { height } = useDimensions(containerRef);

	return (
			<motion.nav initial={false}
									animate={isOpen ? "open" : "closed"}
									ref={containerRef}
									custom={height}>
				<ul className={"nav-ul"}>
					<motion.div className={"background"}
											variants={sidebar} />
					<Navigation currentPath={currentPath}
											isOpen={isOpen}
											toggleEvent={() => toggleOpen()} />
					<MenuToggle toggleEvent={() => toggleOpen()} />
				</ul>

				{/* Side links below are only shown on bigger displays */}
				{
					NavLinks({currentPath, className}).map(
							(element) => (element)
					)
				}
			</motion.nav>
	);
}

export default Navbar;
